import { Link } from 'gatsby'
import * as React from 'react'
import styles from './specificButton.module.scss'

type Props = {
  videoId: number
}

const SpecificButton: React.FC<Props> = ({ videoId }) => {
  return (
    <Link to={`/video/${videoId}`}>
      <button className={styles.specificButton}>解説詳細を見る</button>
    </Link>
  )
}

export default SpecificButton
