import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import styles from './[contentId].module.scss'

import Seo from '@/components/general/seo'
import SpecificButton from '@/components/general/specificButton'
import Layout from '@/components/layout/layout'
import SectionTitle from '@/components/top/section-title'

import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="Aさんに「寒いから気をつけてね」と言っても言葉の意味が分かっていない様子だったので「寒いから風邪ひかないように上着を着ましょう」と言い直したところ理解が出来た様子。Aさんに想定される中核症状は？" />
      <div className={styles.wrap}>
        <div className={styles.wrapInner}>
          <div className={styles.answer}>
            <p className={styles.comment}>理解・判断力の障害</p>
          </div>
          <div className={styles.moreWrap}>
            <div className={styles.more}>
              <span className={styles.moreText}>詳しく解説</span>
            </div>
            <div className={styles.dotLine} />
          </div>
          <div className={styles.explanation}>
            <p>
              理解・判断力の障害とは、物事の理解に時間がかかり、一度に複数のことを言われる、行動しようとすると理解が難しくなる症状です。また、いつもと違う出来事に対して混乱する場合があります。このような場合は具体的な表現で指示を心がけましょう。
            </p>
          </div>
          <SectionTitle theme="dark">Cognition Cafe Reserve</SectionTitle>
          <div className={styles.video}>
            <ReactPlayerLoader
              accountId={ACCOUNT_ID}
              videoId="6319151730112"
              playerId="hM1SBfBel"
              embedOptions={{ responsive: 'aspectRatio' }}
            />
            <p className={styles.caption}>
              大日本住友製薬（現：住友ファーマ株式会社）
            </p>
          </div>
          <SpecificButton videoId={8} />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={
              'Aさんに「寒いから気をつけてね」と言っても言葉の意味が分かっていない様子だったので「寒いから風邪ひかないように上着を着ましょう」と言い直したところ理解が出来た様子。Aさんに想定される中核症状は？'
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
