import * as React from 'react'
import styles from './section-title.module.scss'

type Props = {
  theme: 'light' | 'dark'
}

const SectionTitle: React.FC<Props> = ({ children, theme }) => (
  <div className={`${styles.wrap} ${styles[theme]}`}>
    <span className={styles.title}>{children}</span>
  </div>
)

export default SectionTitle
